<template>
    <v-dialog
        v-model="dialog"
        max-width="500"
    >
        <template #activator="{ on }">
            <v-btn
                class="mr-2"
                v-on="on"
                color="primary"
                v-bind="$attrs"
            >
                {{ $t('addEntity', [$t('group')]) }}
            </v-btn>
        </template>
        <v-card>
            <v-card-title>
                {{ $t('addEntity', [$t('group')]) }}
            </v-card-title>
            <v-card-text>
                <v-row>
                    <v-col cols="12">
                        <v-select
                            v-model="payload.group"
                            :items="groups"
                            :label="$t('group')"
                            item-text="name"
                            item-value="_id"
                            return-object
                            outlined
                            hide-details
                        />
                    </v-col>
                    <v-col cols="12">
                        <v-text-field
                            v-model.number="payload.multiplier"
                            :label="$t('multiplier')"
                            type="number"
                            outlined
                            hide-details
                        />
                    </v-col>

                    <v-col cols="12">
                        <v-btn
                            @click="submit"
                            :disabled="!payload.group"
                            color="primary"
                            block
                        >
                            {{ $t('add') }}
                        </v-btn>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    inheritAttrs: false,
    data() {
        return {
            dialog: false,
            group: null,
            groups: [],
            payload: {
                group: null,
                multiplier: 1
            }
        }
    },
    methods: {
        async setGroups() {
            const response = await this.$api.getProductGroups()

            if (response.error) return

            this.groups = response.message
        },
        submit() {
            const items = this.payload.group.products.map(gp => {
                return {
                    group: this.payload.group._id,
                    product: gp.product,
                    quantity: gp.quantity * this.payload.multiplier
                }
            });

            this.$emit('submit', items)

            this.dialog = false
        }
    },
    created() {
        this.setGroups()
    }
}
</script>

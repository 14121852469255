import { i18n } from "@/plugins/i18n";

export default {
    namespaced: true,
    state: {
        types: [
            {
                text: i18n.t('proposal'),
                value: 'proposal',
            },
            {
                text: i18n.t('custom'),
                value: 'custom',
            },
        ]
    }
}

const toastr = require("toastr");
const toastrOptions = { transitionIn: "fadeIn", transitionOut: "fadeOut", position: "top-right", progressBar: true };

import { i18n } from "@/plugins/i18n";
import get from "lodash/get";

export const emitToastr = (type = "error", title = "", message = "") => {
    return toastr[type](title, message, toastrOptions);
};


export const PMT = (ir, np, pv, fv, type) => {
    /*
   * ir   - interest rate per month
   * np   - number of periods (months)
   * pv   - present value
   * fv   - future value
   * type - when the payments are due:
   *        0: end of the period, e.g. end of month (default)
   *        1: beginning of period
   */
    var pmt, pvif;

    fv || (fv = 0);
    type || (type = 0);

    if (ir === 0)
        return -(pv + fv)/np;

    pvif = Math.pow(1 + ir, np);
    pmt = - ir * (pv * pvif + fv) / (pvif - 1);

    if (type === 1)
        pmt /= (1 + ir);

    return pmt;
}

export function getProposalStatusColor(status, type = 'text') {
    const textColors = {
        new: 'blue',
        in_negotiation: 'purple',
        pending_approval: 'warning',
        approved: 'success',
        archived: 'gray',
    }

    const hexColors = {
        new: '#3498db',
        in_negotiation: '#9C27B0',
        pending_approval: '#ff9800',
        approved: '#4caf50',
        archived: '#9e9e9e',
    }

    if (type === 'hex') return hexColors[status]

    return textColors[status] 
}

export function getProposalStatusLabel(status) {
    const labels = {
        new: i18n.t('new'),
        in_negotiation: i18n.t('inNegotiation'),
        pending_approval: i18n.t('pendingApproval'),
        approved: i18n.t('approved'),
        archived: i18n.t('archived'),
    }

    return labels[status] || 'Desconhecido'
}

export function roundFloat(value) {
    return Number(value.toFixed(2))
}

export function print(filename) {
    let previousTitle = document.title

    document.title = filename || document.title

    window.print()

    document.title = previousTitle
}

export function copy(payload) {
    return JSON.parse(JSON.stringify(payload))
}

export async function pickFile(options = {}) {
    return new Promise((resolve) => {
        const input = document.createElement('input')
        
        input.type = 'file'
        
        input.accept = options.accept || '*/*'
        input.multiple = options.multiple || false
        
        input.onchange = e => {
            if (options.multiple) {
                resolve(Array.from(e.target.files))
                return
            }

            resolve(e.target.files[0])
        }

        input.click()
    })
}

export function normalize(value = '') {
    return value.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toLocaleLowerCase()
}

export function searchObject(search, object, keys) {
    const searchValue = normalize(search)

    return keys.some(key => {
        const value = normalize(get(object, key, ''))

        return value.includes(searchValue)
    })
}
<template>
    <v-navigation-drawer
        class="drawer"
        v-model="drawer"
        :clipped="$vuetify.breakpoint.lgAndUp"
        width="270"
        app
        dark
        color="galaxy"
    >
        <router-link
            to="/"
            class="d-block my-10"
        >
            <v-img
                :src="$appConfig.images.drawerLogo"
                width="200"
                height="53"
                contain
                class="mx-auto"
            />
        </router-link>

        <v-list
            dense
            expand
        >
            <v-list-item
                to="/proposals/new"
                class="primary"
                v-if="currentUser.role != 'assembler'"
            >
                <v-list-item-icon>
                    <v-icon>mdi-storefront</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                    <v-list-item-title>
                        {{ $t('proposalGenerator') }}
                    </v-list-item-title>
                </v-list-item-content>
            </v-list-item>

            <template v-for="(item, i) in menuItems.filter(m => !m.hide)">
                <v-list-group
                    v-if="item.children"
                    :value="true"
                    :key="i"
                    :prepend-icon="item.icon"
                    active-class="white--text"
                >
                    <template #activator>
                        <v-list-item-title>{{ item.label }}</v-list-item-title>
                    </template>

                    <v-list-item
                        v-for="(child, ci) in item.children.filter(m => !m.hide)"
                        :key="ci"
                        :to="child.to"
                        class="white--text"
                        exact
                    >
                        <v-list-item-icon>
                            <span class="mx-auto">
                                -
                            </span>
                        </v-list-item-icon>

                        <v-list-item-title>
                            {{ child.label }}
                        </v-list-item-title>
                    </v-list-item>
                </v-list-group>

                <v-list-item
                    v-else
                    :key="`item-${i}`"
                    :to="item.to"
                >
                    <v-list-item-icon>
                        <v-icon>{{ item.icon }}</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>
                            {{ item.label }}
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </template>
        </v-list>
    </v-navigation-drawer>
</template>

<script>
export default {
    name: 'AppDrawer',
    props: {
        value: {
            type: Boolean,
            default: false
        },
    },
    computed: {
        drawer: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('input', value);
            }
        },
        currentUser() {
            return this.$store.state.currentUser
        },
        isAdmin() {
            return this.currentUser?.admin;
        },
        menuItems() {
            return [
                {
                    label: "Dashboard",
                    icon: "mdi-view-dashboard",
                    to: '/',
                    hide: !['admin', 'seller'].includes(this.currentUser?.role)
                },
                {
                    label: this.$t('proposals'),
                    icon: "mdi-file-document",
                    to: '/proposals',
                    hide: !['admin', 'seller'].includes(this.currentUser?.role)
                },
                {
                    label: this.$tc('myProfile'),
                    icon: "mdi-account-circle-outline",
                    to: '/my-profile',
                    hide: !['admin', 'seller'].includes(this.currentUser?.role)
                },
                {
                    label: this.$tc('client', 2),
                    icon: "mdi-account-group",
                    hide: !['admin', 'seller'].includes(this.currentUser?.role),
                    children: [
                        {
                            label: this.$t('entityList', [this.$tc('client', 2)]),
                            to: '/customers'
                        },
                        {
                            label: this.$t('proposalsPerClient'),
                            to: '/proposals-per-customer'
                        }
                    ]
                },
                {
                    label: this.$t('products'),
                    icon: "mdi-package-variant-closed",
                    hide: this.currentUser?.role !== 'admin',
                    children: [
                        {
                            label: this.$t('entityList', [this.$t('products')]),
                            to: '/products'
                        },
                        {
                            label: this.$t('groups'),
                            to: '/product-groups'
                        },
                        {
                            label: this.$tc('category', 2),
                            to: '/categories'
                        },
                        {
                            label: this.$tc('subcategory', 2),
                            to: '/subcategories'
                        },
                    ]
                },
                {
                    label: this.$t('tickets'),
                    icon: "mdi-face-agent",
                    to: '/tickets',
                    hide: this.currentUser?.role !== 'admin',
                },
                {
                    label: this.$tc('supplier', 2),
                    icon: "mdi-store",
                    to: '/suppliers',
                    hide: this.currentUser?.role !== 'admin',
                },
                {
                    label: this.$tc('user', 2),
                    icon: "mdi-account-multiple",
                    to: '/users',
                    hide: this.currentUser?.role !== 'admin',
                },
                {
                    label: this.$tc('order', 2),
                    icon: "mdi-factory",
                    hide: this.currentUser?.role !== 'admin',
                    children: [
                        {
                            label: this.$t('entityList', [this.$tc('order', 2)]),
                            to: "/orders",
                        },
                        {
                            label: this.$t('predefinedFields'),
                            to: "/order-settings",
                        }
                    ]
                },
                {
                    label: this.$t('commissions'),
                    icon: "mdi-currency-usd",
                    hide:  !['admin', 'seller'].includes(this.currentUser?.role),
                    children: [
                        {
                            label: this.$t('reports'),
                            to: '/commission-reports',
                            hide: this.currentUser?.role !== 'admin' 
                        },
                        {
                            label: this.$t('settings'),
                            to: '/commission-settings',
                            hide: this.currentUser?.role !== 'admin' 
                        },
                        {
                            label: 'Dashboard',
                            to: '/seller-commission-dashboard',
                            hide: !this.currentUser?.commission_setting_id,
                        },
                        {
                            label: this.$t('commissionsReceived'),
                            to: '/seller-commissions',
                            hide: !this.currentUser?.commission_setting_id
                        }
                    ]
                },
                {
                    label: this.$tc('setting', 2),
                    hide: this.currentUser?.role !== 'admin',
                    icon: "mdi-cog",
                    to: "/settings",
                },

                {
                    label: this.$tc('myProfile'),
                    icon: "mdi-account-circle-outline",
                    to: '/my-profile',
                    hide: this.currentUser?.role !== 'assembler'
                },

                {
                    label: this.$tc('client', 2),
                    icon: "mdi-account-group",
                    hide: this.currentUser?.role !== 'assembler',
                    children: [
                        {
                            label: this.$t('entityList', [this.$tc('client', 2)]),
                            to: '/customers'
                        }
                    ]
                },
                {
                    label: this.$t('tickets'),
                    icon: "mdi-face-agent",
                    to: '/tickets',
                    hide: this.currentUser?.role !== 'assembler'

                },

            ]
        },
    },

}
</script>

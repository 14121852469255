import { i18n } from "@/plugins/i18n";

export default {
    namespaced: true,
    state: {
        orderProposalStatus: [
            {
                text: i18n.t('unknown'),
                value: 'unknown',
                // color: 'grey',
            },
            {
                text: i18n.t('inProduction'),
                value: 'inProduction',
                color: '#ABABAB',
                dark: true,
            },
            {
                text: i18n.t('inCutAndFold'),
                value: 'inCutAndFold',
                color: '#9370DB',
                dark: true,
            },
            {
                text: i18n.t('painting'),
                value: 'painting',
                color: '#4B0082',
                dark: true,
            },
            {
                text: i18n.t('drying'),
                value: 'drying',
                color: '#FFA07A',
                dark: true,
            },
            {
                text: i18n.t('preAssembly'),
                value: 'preAssembly',
                color: '#FFA500',
                dark: true,
            },
            {
                text: i18n.t('inQualityControl'),
                value: 'inQualityControl',
                color: '#FF8C00',
                dark: true,
            },
            {
                text: i18n.t('readyToShip'),
                value: 'readyToShip',
                color: '#ADD8E6',
                dark: true,
            },
            {
                text: i18n.t('seaRoute'),
                value: 'seaRoute',
                color: '#0000CD',
                dark: true,
            },
            {
                text: i18n.t('destinationPort'),
                value: 'destinationPort',
                color: '#00008B',
                dark: true,
            },
            {
                text: i18n.t('awaitingCargoRelease'),
                value: 'awaitingCargoRelease',
                color: 'blue',
                dark: true,
            },
            {
                text: i18n.t('roadRoute'),
                value: 'roadRoute',
                color: 'orange',
                dark: true,
            },
            {
                text: i18n.t('storedInDC'),
                value: 'storedInDC',
                color: 'orange',
                dark: true,
            },
            {
                text: i18n.t('assembling'),
                value: 'assembling',
                color: 'pink',
                dark: true,
            },
            {
                text: i18n.t('delivered'),
                value: 'delivered',
                color: 'green',
                dark: true,
            }
        ],
    }
}

<template>
    <v-dialog v-model="dialog">
        <template #activator="{ on }">
            <v-img
                v-bind="$attrs"
                v-on="on"
                :src="src"
            />
        </template>

        <div @click="dialog = false">
            <v-card>    
                <v-card-text>
                    <v-img
                        :src="src"
                        width="80vh"
                        height="auto"
                        class="mx-auto"
                    />
                </v-card-text>
            </v-card>
        </div>
    </v-dialog>
</template>

<script>
export default {
    props: {
        src: {
            type: [String, Object],
            required: true,
        },
    },
    data: () => ({
        dialog: false,
    }),
};
</script>
import { i18n } from "@/plugins/i18n";

function sortByText(a, b) {
    return a.text.localeCompare(b.text);
}

export default {
    namespaced: true,
    state: {
        status: [
            {
                text: i18n.t('new'),
                value: 'new',
                color: 'purple',
                dark: true,
            },
            {
                text: i18n.t('inProgress'),
                value: 'inProgress',
                color: 'info',
                dark: true,
            },
            {
                text: i18n.t('done'),
                value: 'done',
                color: 'success',
                dark: true,
            },
        ],
        types: [
            {
                text: i18n.t('structure'),
                value: 'structure',
                color: 'warning',
            },
            {
                text: i18n.t('upholstery'),
                value: 'upholstery',
                color: 'warning',
            },
            {
                text: i18n.t('fairing'),
                value: 'fairing',
                color: 'warning',
            },
            {
                text: i18n.t('digitalCounter'),
                value: 'digitalCounter',
                color: 'warning',
            },
            {
                text: i18n.t('weightTower'),
                value: 'weightTower',
                color: 'warning',
            },
            {
                text: i18n.t('steelCable'),
                value: 'steelCable',
                color: 'warning',
            },
            {
                text: i18n.t('bearingsAndShafts'),
                value: 'bearingsAndShafts',
                color: 'warning',
            },
            {
                text: i18n.t('ledPanel'),
                value: 'ledPanel',
                color: 'warning',
            },
            {
                text: i18n.t('touchPanel'),
                value: 'touchPanel',
                color: 'warning',
            },
            {
                text: i18n.t('oiling'),
                value: 'oiling',
                color: 'warning',
            },
            {
                text: i18n.t('pinsAndAdjustments'),
                value: 'pinsAndAdjustments',
                color: 'warning',
            },
            {
                text: i18n.t('belt'),
                value: 'belt',
                color: 'warning',
            },
            {
                text: i18n.t('crankset'),
                value: 'crankset',
                color: 'warning',
            },
            {
                text: i18n.t('painting'),
                value: 'painting',
                color: 'warning',
            },
            {
                text: i18n.t('pulley'),
                value: 'pulley',
                color: 'warning',
            },
            {
                text: i18n.t('others'),
                value: 'others',
                color: 'warning',
            }
        ].sort(sortByText),
        natures: [
            {
                text: i18n.t('preventive'),
                value: 'preventive',
                color: 'error',
            },
            {
                text: i18n.t('manufacturingDefect'),
                value: 'manufacturingDefect',
                color: 'error',
            },
            {
                text: i18n.t('misuse'),
                value: 'misuse',
                color: 'error',
            },
            {
                text: i18n.t('assembly'),
                value: 'assembly',
                color: 'error',
            },
            {
                text: i18n.t('earlyWear'),
                value: 'earlyWear',
                color: 'error',
            }
        ].sort(sortByText)
    }
}
<template>
    <div>
        <!-- Elemento do chatbot -->
        <zapier-interfaces-chatbot-embed
            v-if="showChatbot && currentUser && ['admin', 'seller'].includes(currentUser.role)"
            is-popup="true"
            chatbot-id="cm1h4p1sr000cqqh5pcdjah4x"
            height="600px"
            width="400px"
        />
    </div>
</template>

<script>
export default {
    data() {
        return {
            showChatbot: false,
        };
    },
    mounted() {
        this.triggerChatbot();
    },
    computed: {
        currentUser() {
            return this.$store.state.currentUser;
        }
    },
    methods: {
        loadZapierScript() {
            return new Promise((resolve, reject) => {
                if (document.getElementById('zapierScript')) {
                    resolve();
                    return;
                }

                const script = document.createElement('script');
                script.id = 'zapierScript';
                script.async = true;
                script.type = 'module';
                script.src = this.$appConfig.zapierChatbotScript;
                script.onload = resolve;
                script.onerror = reject;
                document.head.appendChild(script);
            });
        },
        triggerChatbot() {
            this.loadZapierScript()
                .then(() => {
                    this.showChatbot = true;
                })
                .catch((error) => {
                    console.error('Falha ao carregar o script do Zapier:', error);
                });
        },
    },
};
</script>